import React from "react"

function ErrorPage() {
  return (
    <div>
      <h1>404 Page Not Found</h1>
    </div>
  )
}

export default ErrorPage
